import Label from "../../components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "../../shared/Avatar/Avatar";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import Input from "../../shared/Input/Input";
import Select from "../../shared/Select/Select";
import Textarea from "../../shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import Grid from '@mui/material/Grid2';
import { useParams } from "react-router";
import { Box, Paper, Button, IconButton, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useEventDashboardLoader from "hooks/useEventDashboardLoader";
import Heading from "components/Heading/Heading";
import WidgetHeading1 from "containers/BlogPage/WidgetHeading1";
import CardCategory1 from "components/CardCategory1/CardCategory1";
import { DEMO_CATEGORIES } from "data/taxonomies";
import { TaxonomyType } from "data/types";
import EventCheckInUserCard from "components/EventManagementCard/EventCheckInUserCard";
import { onSnapshot, getDocs, doc, collection, query, where, orderBy, DocumentData } from "firebase/firestore";
import { auth, fs } from "firebaseSetup";

export interface AccountPageProps {
  className?: string;
}

const EventCheckIn: FC<AccountPageProps> = ({ className = "" }) => {

    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [allMembers, setAllMembers] = useState<DocumentData[]>([])
    const [checkinMember, setCheckinMembers] = useState<DocumentData[]>([])


    const [eventDashboard, setEventDashboard] = useState({
      dashboardItems: [
          {
              title: '',
              total: 0,
              time: '',
              notice: '',
          }
      ],
      eventItem: {
          title: 'Loading...',
          id: '',
          description: 'Loading...'
      },
      currentTime: 'Loading...',
      mode: 'orders',
      tickets: [],
      members: [],
      orders: [],
  })

  const [currentTime,setCurrentTime] = useState('')


    useEffect(() => {
      console.log('triggered');
      setCurrentTime("")
      loadCheckInEventUsers()
      loadNotCheckInEventUsers()
          let userUID = ""
            userUID = auth.currentUser?.uid || ""
            let userToken = localStorage.getItem('user_token') || ""
            let eventId = id || ""
            if (userToken != null && userToken != "" && eventId != ""){
              

              fetch(`https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAuthenticatedGetOrganizerDetailedEvents?event_id=${eventId}&full_data=${true}&mode=orders`,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + userToken
              },
  
       }).then((response) => {
           const responseTask = response.json()
           responseTask.then((data) => {
               let dashboardItem = eventDashboard
               if (data.success != null && data.success){
                   //load data
                   if (data.currentTime != null){
                       dashboardItem.currentTime = data.currentTime
                   }
                   
                   if (data.overview != null){
                       console.log(data.overview)
                       let overview = {...data.overview}
                       dashboardItem.dashboardItems = [
                           {
                             title: 'Total Sales',
                             total: overview.totalSales + "CAD",
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Tickets Sold',
                             total: overview.tickets,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Total Members',
                             total: overview.members,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Page Views',
                             total: overview.views,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Total Form Submissions',
                             total: overview.formSubmissions,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Total Invoices',
                             total: overview.invoices,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                         ]
                   }
                   
                   if (data.event != null){
                       dashboardItem.eventItem = data.event
                   }
                   if (data.orders != null){
                       if (data.orders.data != null){
                          let orderData = []
                          
                          dashboardItem.orders = data.orders.data
                       }
                       
                   }
                   if (data.tickets != null){
                     if (data.tickets.data != null){
                       dashboardItem.tickets = data.tickets.data
                     }
                   }
                   setCurrentTime(dashboardItem.currentTime)
                   setEventDashboard(dashboardItem)
               }
               else{
                 console.log(data)
                 
               }
               

           })
       })


            }
          else{

          }

  }, [eventDashboard]);

    // useEffect(()=>{
    //   //load all users
    //   loadCheckInEventUsers()
    // },[])

    async function loadCheckInEventUsers(){
      let eventId = id || ""
      if (eventId != ""){
        //get
        const memberRef = query(collection(fs, "events",eventId,'members'), where('checkinStatus','==', true), orderBy('timestamp','desc'));
        
        getDocs(memberRef).then((snapshot) => {
          let members: DocumentData[] = []
          snapshot.docs.forEach((doc) => {
            members.push(doc.data())
          })
          setCheckinMembers(members)
        })
      }
    }

    async function loadNotCheckInEventUsers(){
      let eventId = id || ""
      if (eventId != ""){
        //get
        const memberRef = query(collection(fs, "events",eventId,'members'), orderBy('timestamp','desc'));
        
        getDocs(memberRef).then((snapshot) => {
          let members: DocumentData[] = []
          snapshot.docs.forEach((doc) => {
            members.push(doc.data())
          })
          setAllMembers(members)
        })
      }
    }

    return (
      <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event Organizer Dashboard</title>
      </Helmet>
      <CommonLayout eventId={id || ""} eventTitle={eventDashboard.eventItem.title}>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-semibold">Members Not Yet Checkin ({allMembers.length})</h2>
            <ButtonPrimary>Scan</ButtonPrimary>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {
            allMembers.map((docData) => {
              return <EventCheckInUserCard name={docData.name} checkinStatus={docData.checkinStatus} ticketId={docData.ticketId} tickets={docData.tickets}/>
            })
          }
          </div>
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-semibold">Members Already Checkin ({allMembers.length})</h2>
          </div>
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {
            allMembers.map((docData) => {
              return <EventCheckInUserCard name={docData.name} checkinStatus={docData.checkinStatus} ticketId={docData.ticketId} tickets={docData.tickets}/>
            })
          }
          </div>
        </div>
      </CommonLayout>
    </div>
    )
};

export default EventCheckIn;
