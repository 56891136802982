import StartRating from "components/StartRating/StartRating";
import React, { FC, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom"
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import { EventFormQuestionType, EventPriceDataType } from "data/types";
import { Paper, Typography,  Skeleton, Stack, Card, CardHeader, CardContent, CardMedia, CardActions, IconButton, Button } from '@mui/material'
import ButtonSecondary from "shared/Button/ButtonSecondary";
import PhoneInput from 'react-phone-number-input'
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import { eventGoFormSubmission } from "../../hooks/EventGoAPI"
import { auth,fs } from "firebaseSetup"
import { signInAnonymously } from "firebase/auth"
import { Timestamp, setDoc, doc } from "firebase/firestore"

export interface PrivateInvitePageProps {
  className?: string;
}

const PrivateInvitationPage: FC<PrivateInvitePageProps> = ({ className = "" }) => {

    const [eventLoading, setEventLoading] = useState<boolean>(true)
    const { id } = useParams()
    const [searchParams] = useSearchParams();
    const inviteCode = searchParams.get('inviteCode') || "";
    const status = searchParams.get('status') || "";
    const [formQuestions, setFormQuestions] = useState<EventFormQuestionType[]>([])

    const [loadingOperation, setLoadingOperation] = useState<boolean>(false)
    const [eventData, setEventData] = useState({
        photoURL: "",
        address: {
            fullAddress: "",
        },
        title: "",
        description: "",
        startDateString: "",
    })
    const [inviteD, setInviteD] = useState({
        firstName: "",
        lastName: "",
    })
    const [invitationData, setInvitationData] = useState({
        email: "",
        firstName: "",
        lastName: "",
        priceId: "",
        quantity: 1,
        status: '',
        inviteCode: '',
        url: '',
        priceDescription: '',
    })
    const [inviteStatus, setInviteStatus] = useState<string>('')

    async function acceptInvitation(){
        //check if questions

        let formSubmissionId = ""
    let formSubmissionItem: any[] = []
    let formSubmissionRequiredFormTitles: string[] = []
    let formSubmissionRequiredFormIds: string[] = []
    let formSubmissionFilledAll = true
    formQuestions.forEach((formItm)=>{
      //if one question that is required and its not filled yet, dont submit
      let formValue = ""
      if (formItm.value != null){
        if (formItm.value != ""){
          formValue = formItm.value
        }
      }
      if (formItm.required && formValue == ""){
        formSubmissionRequiredFormTitles.push(formItm.title)
        formSubmissionRequiredFormIds.push(formItm.id)

        formSubmissionFilledAll = false
      }
      formSubmissionItem.push({
        id: formItm.id,
        title: formItm.title,
        value: formValue,
      })
    })

    if (formSubmissionFilledAll && invitationData.firstName != "" && invitationData.lastName != ""){
        setLoadingOperation(true)
        const sId = await eventGoFormSubmission(
            id || '',
            invitationData.email,
            invitationData.firstName,
            invitationData.lastName,
            formSubmissionItem,
          )
        formSubmissionId = sId

        if (auth.currentUser != null){
            const user_uid = auth.currentUser.uid
            if (user_uid != ""){
                invitationAcceptAction(user_uid, formSubmissionId)
            }
            else{
                
            }
        }
        else{
            signInAnonymously(auth).then((userRef) => {
                const user_uid = userRef.user.uid
                localStorage.setItem("user_uid",user_uid)
                userRef.user.getIdToken(true).then((idToken)=>{
                    if (idToken != ""){
                      localStorage.setItem('user_token',idToken)
                    }
                })
                let userDataa = {
                    'firstName':invitationData.firstName,
                    'lastName':invitationData.lastName,
                    'email':invitationData.email,
                    'currentEvent': id || "",
                    'lang': 'en',
                    'userName': invitationData.firstName + " " + invitationData.lastName,
                    'name': invitationData.firstName + " " + invitationData.lastName,
                    'profileURL':'',
                    'isAnonymous': true,
                    'timestamp': Timestamp.fromDate(new Date()),
                }
                
                const userDataString = JSON.stringify({"user": userDataa})
                localStorage.setItem("user",userDataString)
                setDoc(doc(fs,'users',user_uid),userDataa)
                invitationAcceptAction(user_uid, formSubmissionId)

            }).catch((e)=>{
                console.log(e)
            })
        }




    }
    else {
        //name or required fields
        if (!formSubmissionFilledAll){
            alert('You have to fill required fields')
        }
        if (invitationData.firstName == "" || invitationData.lastName == ""){
            alert('You have to enter your name')
        }
    }

} 

    function invitationAcceptAction(user_uid:string, form_id:string){
        setLoadingOperation(true)
            fetch(
                "https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPIEventFetchAndAcceptPrivateInvite",{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                event_id: id || "",
                invite_code: inviteCode,
                first_name: invitationData.firstName,
                last_name: invitationData.lastName,
                status: 'accepted',
                uid: user_uid,
                formId: form_id,
            }),}).then((response) => {
                const responseTask = response.json();
                responseTask.then((data) => {
                    if (data.success) {
                        setInviteStatus('accepted')
                        window.location.assign('/account-orders')
                    }
                    else{
                        //failed
                    }
                    setLoadingOperation(false)
                })
            })

    }

    function rejectInvitation(){
        setLoadingOperation(true)
        fetch(
            "https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPIEventFetchAndAcceptPrivateInvite",{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            event_id: id || "",
            invite_code: inviteCode,
            first_name: invitationData.firstName,
            last_name: invitationData.lastName,
            status: 'rejected'
        }),}).then((response) => {
            const responseTask = response.json();
            responseTask.then((data) => {
                if (data.success) {
                    setInviteStatus('rejected')
                }
                else{
                    //failed
                }
                setLoadingOperation(false)
            })
        })
    } 
    

    useEffect(()=>{
        let eventId = id || ""
        if (eventId != "" && inviteCode != ""){
            fetch(
                "https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPIEventFetchAndAcceptPrivateInvite",{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                event_id: eventId,
                invite_code: inviteCode,
                first_name: '',
                last_name: '',
            })
        }).then((response) => {
            const responseTask = response.json();
            responseTask.then((data) => {
                let responseData = {
                    status: "failure",
                    success: false,
                    message: '',
                    action: "/",
                    event: {...eventData},
                    invite: {...invitationData},
                    price: {},
                    forms: {data: [], count: 0},
                }
                responseData = data
                if (!responseData.success){
                    //no access
                    window.location.assign('/e/'+eventId)
                }
                else{
                    //load data
                    setEventLoading(false)
                    if (responseData.event != null){
                      setEventData(responseData.event)
                    }
                    if (responseData.invite != null){
                      setInvitationData(responseData.invite)
                    }
                    if (responseData.forms != null){
                        if (responseData.forms.data != null){
                            setFormQuestions(responseData.forms.data)
                        }
                    }
                }
            })
        })
        }
        else{
            window.location.assign('/')
        }
    },[])

    function renderLoading(){
        return (
          <Card sx={{ width: '100%', marginTop: '15px', marginBottom: '15px', height: '90vh'}}>
            <CardHeader
              avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
              title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }}/>}
              subheader={<Skeleton animation="wave" height={10} width="40%" />}
            />
            <Skeleton sx={{ height: '60vh' }} animation="wave" variant="rectangular" />
            <CardContent>
              <React.Fragment>
                  <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                  <Skeleton animation="wave" height={10} width="80%" />
                </React.Fragment>
            </CardContent>
          </Card>
        )
      }

      function renderFirstLastName(){
          return (<div className="mt-3">
              <div className="space-y-1 mt-2">
                          <Label>First Name</Label>
                          <Input type={'text'} defaultValue={invitationData.firstName} onChange={(e)=>{
                            let cval = e.currentTarget.value
                            setInviteD({...inviteD, firstName: cval})
                          }}/>
                          </div>
                          <div className="space-y-1 mt-2">
                          <Label>Last Name</Label>
                          <Input type={'text'} defaultValue={invitationData.lastName} onChange={(e)=>{
                            let cval = e.currentTarget.value
                            setInviteD({...inviteD, lastName: cval})
                          }}/>
                          </div>

          </div>)
      }

function renderQuestions(){
    return (<div className="mt-3">
        {
                    formQuestions.map((ques,index) => {
                      let labelTextStyle = "text-black"
                      
                      if (ques.optionType == "options"){
                        //select
                        return <div className="space-y-1 mt-2">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Select onChange={(e)=>{
                            let allQues = formQuestions
                            let cQues = ques
                            cQues.value = e.target.value || ""
                            allQues[index] = cQues
                            setFormQuestions(allQues)
                          }}>
                          <option value={''}>{'--- select option ---'}</option>
                            {
                              ques.options.map((itm)=>{
                                return <option value={itm}>{itm}</option>
                              })
                            }
                          </Select>
                          </div>
                      }
                      else if (ques.optionType == "text"){
                        if (ques.valueType == "phone"){
                          return <div className="space-y-1 mt-2">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <PhoneInput
                                  placeholder={ques.title}
                                  value={""}
                                  defaultCountry="CA"
                                  onChange={(e) => {
                                    let allQues = formQuestions
                                    let cQues = ques
                                    cQues.value = e || ""
                                    allQues[index] = cQues
                                    setFormQuestions(allQues)
                                  }}/>
                          </div>
                        }
                        else{
                          return <div className="space-y-1 mt-2">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Input type={'text'} defaultValue="" onChange={(e)=>{
                            let allQues = formQuestions
                            let cQues = ques
                            cQues.value = e.currentTarget.value
                            allQues[index] = cQues
                            setFormQuestions(allQues)
                          }}/>
                          </div>
                        }
                        
                      }
                      else if (ques.optionType == "file"){
                        return <div className="space-y-1 mt-2">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Input type="file" defaultValue="" />
                          </div>
                      }
                      else if (ques.optionType == "textarea"){
                        return <div className="space-y-1 mt-2">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Textarea onChange={(e)=>{
                            let allQues = formQuestions
                            let cQues = ques
                            cQues.value = e.currentTarget.value
                            allQues[index] = cQues
                            setFormQuestions(allQues)
                          }}
                          />
                          </div>
                      }
                    })
                  }
    </div>)
}

  

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          You have been invited!
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">You need to RSVP to event</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={eventData.photoURL} />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {invitationData.quantity} X {invitationData.priceDescription}
                </span>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  {eventData.title}
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {"Please review the details of invitation"}
              </span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Event Date</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {eventData.startDateString}
                </span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Event Location</span>
                <span className="mt-1.5 text-lg font-semibold">{eventData.address.fullAddress}</span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Invitation Details</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Invitation Code</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {inviteCode}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Tickets</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
              {invitationData.quantity} X {invitationData.priceDescription}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">First Name</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {invitationData.firstName}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Last Name</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {invitationData.lastName}
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Email</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {invitationData.email}
              </span>
            </div>
          </div>
        </div>
        {(invitationData.firstName == "" || invitationData.lastName == "") && renderFirstLastName()}
        {formQuestions.length > 0 && renderQuestions()}
        {inviteStatus == "" && <div>
          <ButtonPrimary
            loading={loadingOperation} 
            onClick={()=>{
                //accept
                if (invitationData.firstName == "" || invitationData.lastName == ""){
                    setInvitationData({...invitationData, 
                        firstName: inviteD.firstName, 
                        lastName: inviteD.lastName})
                }
                acceptInvitation()
            }}>Accept Invite</ButtonPrimary>
          <ButtonSecondary 
            className="ml-3" 
            loading={loadingOperation}
            onClick={()=>{
                rejectInvitation()
            }}>Reject Invite</ButtonSecondary>
        </div> }
        {inviteStatus != "" && <div>
        <h2 className="text-3xl lg:text-4xl font-semibold">
        {inviteStatus == 'accepted' ? 'You have responded YES' : 'You have responded NO'}
      </h2>
        </div> }
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{
        eventLoading ? renderLoading() : renderContent()
        }</div>
      </main>
    </div>
  );
};

export default PrivateInvitationPage;
