import StartRating from "components/StartRating/StartRating";
import { EventTicketType } from "data/types";
import { auth } from "firebaseSetup";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import { QRCodeCanvas } from 'qrcode.react'
import { isMobile, isIOS, isAndroid } from "react-device-detect"
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, linkWithCredential, EmailAuthCredential, EmailAuthProvider } from "firebase/auth";
import ModalOrderSaveTickets from "components/ModalOrderSaveTickets";
import { Paper, Typography, Checkbox,  Skeleton, Stack, Card, CardHeader, CardContent, CardMedia, CardActions, IconButton, Button, Drawer, Box, Alert } from '@mui/material'
import FormItem from "../PageCreateEvent/FormItem"
import Input from "shared/Input/Input";
import { CardAndroid, CardIOS } from "react-wallets";

import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';

export interface OrderConfirmationPageProps {
  className?: string;
}

const OrderConfirmationPage: FC<OrderConfirmationPageProps> = ({ className = "" }) => {
  const { id } = useParams()
  const [orderId, setOrderId] = useState<string>(id || "")
  const [apiLoading, setAPILoading] = useState<boolean>(true)
  const [paymentDetails, setPaymentDetails] = useState({})
  const [ticketTransfer, setTicketTransfer] = useState<boolean>(false)
  const [paymentAmount, setPaymentAmount] = useState<number>(0)
  const [tickets, setTickets] = useState<EventTicketType[]>([])
  const [userIsGuest, setUserIsGuest] = useState({
    guest: true,
    email: "",
    password: "",
  })
  const [orderDetails, setOrderDetails] = useState({
    amount: 0,
    eventId: '',
    userEmail: '',
    userName: '',
    userUID: '',
    paymentInfo: {},
            tickets: [],
            dateSelected: '',
            type: '',
            status: '',
            event: {photoURL: '', title: '',description: ''},
            timestamp: {
              _seconds: 0,
              _nanoseconds: 0
            }
  })
  const [orderDate, setOrderDate] = useState(new Date())
  const [isLoading, setIsLoading] = useState(false)
  const [eventDetails, setEventDetails] = useState({
    photoURL: '',
    title: 'Loading...',
    description: 'Loading...',
    startDateString: '',
    type: "event",
    address: {
      fullAddress: "",
    }
  })

  const MyEventPassAndroid = () => {
    return (
   <CardAndroid
   //card-type
   type='event'
   //styles------------------------------
   backgroundColor='#f5f5f5'
   titleColor='black'
   //header------------------------------
   headerLogoLink='https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/eventgo.JPG'
   title='Event Go'
   //title--------------------------------
   mainTitle={eventDetails.title}
   secondaryTitle={eventDetails.startDateString}
   //infos--------------------------------
   topLeftTitle='Date'
   topLeftValue='Jun 21,2023'
   topRightTitle='Time'
   topRightValue='11:08 AM'
   bottomLeftTitle='Door'
   bottomLeftValue='1'
   bottomCenterTitle='Row'
   bottomCenterValue='1stC'
   bottomRightTitle='Place'
   bottomRightValue='17B'
   //access--------------------------------
   accessType='QR'
   accessCodeId='https://evtgo.com/ticket-id/'
   accessBorder={true}
   //footer--------------------------------
   bannerImage='https://d1csarkz8obe9u.cloudfront.net/posterpreviews/business-event-banner-design-template-392070a11089eb5fb736443db6abef83_screen.jpg?ts=1618400029'
   //details--------------------------------
   holderNameTitle='full name'
   holderName='Alan Jason'
   place='elysee montmartre'
   address='72 Street Name'
   openTimeTitle='opening date / time'
   openTime='2023 21st June, 11 PM'
   startDateTitle='starting date / time'
   startDate='27 Jul 2023 11PM'
   ticketNumberTitle='ticket number'
   ticketNumber='123789654'
   valueTitle='price'
   value='120$'
   termsAndConditionsTitle='our terms'
   termsAndConditions='this are the terms and conditions'
   googleMapsLink='link here'
   phoneNumber='+33 555 555'
   email='email-email.com'
   disableNotifications='disable notifications'
   /> 
   )}

   const MyEventPassIOS = () => {
     return (
      <CardIOS
       //card type----------
       type='event'
       //styles-------------
       backgroundColor='#ffffff'
       titleColor='#1b38db'
       textColor='#ab2'
       //header-------------
       headerLogoLink={'https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/eventgo.JPG'}
       headerTitle={'Event Go'}
       //banner---------------
       bannerImage={eventDetails.photoURL}
       //information----------
       topLeftTitle='Date'
       topLeftValue={eventDetails.startDateString}
       topCenterLeftTitle=''
       topCenterLeftValue=''
       topRightTitle=''
       topRightValue=''
       bottomLeftTitle='Ticket Holder'
       bottomLeftValue={selectedTicket?.userName || ''}
       bottomRightTitle='Date'
       bottomRightValue={selectedTicket?.date || ''}
       //access---------------
       // accessImage='https://www.qrstuff.com/images/default_qrcode.png'
       accessType='QR'
       accessCodeId={`https://evtgo.com/ticket-id/${selectedTicket?.ticketId}`}
       //details--------------
       eventName={eventDetails.title}
       address={eventDetails.address.fullAddress}
       openTimeTitle='doors open time'
       openTime='27 Jul 10:00'
       startDateTitle='Start date and time'
       startDate='27 Jul 11:00'
       endDateTitle='end date and time'
       endDate='28 Jul 22:00'
       ticketNumberTitle='ticket number'
       ticketNumber='6066e4315abbcc1f9526d392'
       priceTitle='ticket price'
       price='100$'
       termsAndConditionsTitle='terms and conditions for the event'
       termsAndConditions='Only for the BEST'
       googleMapsLinkTitle='google maps location'
       googleMapsLink='https://www.google.com/maps?q=0,0'
       phoneNumberTitle='call us'
       phoneNumber='+338776521452'
       emailTitle='email us'
       email='contact@event.fr'
     />

     )
   }

  function LoadingHeaderView(){
    return (
      <Card sx={{ width: '100%', marginTop: '15px', marginBottom: '15px', height: '90vh'}}>
        <CardHeader
          avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
          title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }}/>}
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
        <Skeleton sx={{ height: '50vh' }} animation="wave" variant="rectangular" />
        <CardContent>
          <React.Fragment>
              <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={10} width="80%" />
            </React.Fragment>
        </CardContent>
      </Card>
    )
  }

  async function loadUserOrderDetails(){
    //save id token
    setIsLoading(true)
    localStorage.removeItem('eventView')
    const userToken = localStorage.getItem('user_token')
    if (userToken != null && userToken != ""){
        fetch("https://us-central1-blink-574af.cloudfunctions.net/v1UserAuthenticatedGetUserOrderDetails?order_id="+id, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+ userToken
          },
        })
        .then(response => response.json())
        .then((data) => {
          //console.log(data)
          setAPILoading(false)
          let dataObj = {
            success: false,
            status: 'failure',
            error: '',
            order: {
              amount: 0,
              eventId: '',
              userUID: '',
              userName: '',
              paymentInfo: {},
              tickets: [],
              type: '',
              dateSelected: '',
              status: '',
              userEmail: '',
              event: {photoURL: '', title: '',description: '', startDateString: '', address: {fullAddress: ""}, type: 'event'},
              timestamp: {
                _seconds: 0,
                _nanoseconds: 0
              }
            },
          }
          dataObj = data
          if (dataObj.success){
            //get order details
            let photoURL = ""
            if (dataObj.order.event.photoURL != null){
              photoURL = dataObj.order.event.photoURL
            }
            let dateS = ""
            let orderType = ""
            let status = 'completed'
            let userUid = ""
            let userName = ""
            let userEmail = ""
            if (dataObj.order != null){
              if (dataObj.order.dateSelected != null){
                dateS = dataObj.order.dateSelected
              }
              if (dataObj.order.type != null){
                orderType = dataObj.order.type
              }
              if (dataObj.order.status != null){
                status = dataObj.order.status
              }
              if (dataObj.order.userUID != null){
                userUid = dataObj.order.userUID
              }
              if (dataObj.order.userName != null){
                userName = dataObj.order.userName
              }
              if (dataObj.order.userEmail != null){
                userEmail = dataObj.order.userEmail
              }

            }

            setOrderDetails({
              ...dataObj.order, 
              dateSelected: dateS, 
              type: orderType, 
              status: status,
              userName: userName,
              userUID: userUid,
              userEmail: userEmail})
            setTickets(dataObj.order.tickets)
            if (dataObj.order.amount != null){
              setPaymentAmount(dataObj.order.amount)
            }
            setPaymentDetails(dataObj.order.paymentInfo)
            var dType = "event"
            if (dataObj.order.event.type != null){
              dType = dataObj.order.event.type
            }
            setEventDetails({...dataObj.order.event, 
              photoURL: photoURL, 
              title: dataObj.order.event.title,
              type: dType,
              description: dataObj.order.event.description, 
              startDateString: dataObj.order.event.startDateString,
              address: dataObj.order.event.address})

              //check is user is guest
              if (auth.currentUser != null){
                let userE = ""
                if (dataObj.order.userEmail != null){
                  userE = dataObj.order.userEmail
                }
                setUserIsGuest({
                  guest: auth.currentUser?.isAnonymous,
                  email: userE,
                  password: ""
                })
              }

            setIsLoading(false)

            

          }
          else{
            if (dataObj.error != null && dataObj.error != ""){
              if (dataObj.error == 'token') {
                reloadUserOrderDetails()
              }
            }
            else{
              window.location.assign("/")
            }
          }
        }).catch((e) => {
          console.log("Error: " + e)
          window.location.assign("/")
        })

    }
    else{
      if (auth.currentUser != null){
        const idToken = await auth.currentUser?.getIdToken(false)
        if (idToken != null && idToken != ""){
          localStorage.setItem('user_token',idToken)
          loadUserOrderDetails()
        }
      }
      else{
        window.location.assign('/login')
      }
      
      
    }
    
  }

  async function reloadUserOrderDetails(){
    const idToken = await auth.currentUser?.getIdToken(false)
      if (idToken != null && idToken != ""){
        localStorage.setItem('user_token',idToken)
        loadUserOrderDetails()
      }
  }

  const [ticketTransferData, setTicketTransferData] = useState({
    email: "",
    name: "",
  })
  async function ticketTransferAction(ticketId:string){
    //check
    if (ticketTransferData.email != "" && 
        ticketTransferData.name != "" && 
        ticketTransferData.email.includes("@") && 
        ticketTransferData.email.includes(".") && 
        ticketId != "" && 
        orderId != ""){
          //validated
        const idToken = await auth.currentUser?.getIdToken(true)
        if (idToken != ""){
        fetch("https://us-central1-blink-574af.cloudfunctions.net/v1UserAuthenticatedTransferUserOrderTickets", {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+ idToken
          },
          body: JSON.stringify({
            orderId: orderId,
            tickets: [ticketId],
            email: ticketTransferData.email,
            name: ticketTransferData.name
          }),
        })
        .then(response => response.json())
        .then((data) => {
          if (data.success) {
            setTicketOpen(false)
            window.location.reload()
          }
          else{

          }
        }).catch((e) => {
          window.alert("Transfer Failed: " + e)
        })
      }
    }
    else{
      window.alert("Information is not correct!")
    }
  }

  const [ticketOpen, setTicketOpen] = useState(false)
  const [screenshotTime, setScreenshotTime] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState<EventTicketType>()
  const [addToWalletOpen, setAddToWalletOpen] = useState(false)
  const OrderTicketPass = (
    <Drawer open={addToWalletOpen} onClose={()=>{ setAddToWalletOpen(false) }}>
      {isIOS ? MyEventPassIOS() : MyEventPassAndroid()}
    </Drawer>
  )
  const OrderTicketDetails = (
    <Drawer open={ticketOpen} onClose={()=>{ setTicketOpen(false)}}>
       <Box 
      role="presentation" 
      style={{width: isMobile ? '100vw' : '50vw'}}
      >
        <div className="relative space-y-8" style={{margin: '16px'}}>
        <ButtonSecondary className="ml-3" onClick={()=>{
          setTicketOpen(false)
        }}>←{`  `}Back</ButtonSecondary>
      </div>
      
      {selectedTicket != null && 
      <div className="relative space-y-2" style={{margin: '16px'}}>
        <div className="text-2xl font-semibold">Ticket Details</div>
        <div className="text-xl font-normal">Ticket Name: <b>{selectedTicket.ticketName}</b></div>
        <div className="text-xl font-normal">Ticket Id: <b>{selectedTicket.ticketId}</b></div>
        <div className="text-xl font-normal">Buyer Name: <b>{selectedTicket.userName}</b></div>
        <div className="text-xl font-normal">Order ID: <b>{selectedTicket.orderId}</b></div>
        {selectedTicket.status != null && <div className="text-xl font-normal">Status: <b>{selectedTicket.status}</b></div>}
        <div style={{marginTop: '25px'}}>
              <QRCodeCanvas
                                    id="qrCode"
                                    value={
                                      "https://evtgo.com/ticket-id/" + selectedTicket.ticketId
                                    }
                                    size={isMobile ? 128 : 200}
                                    bgColor={"#ffffff"}
                                    fgColor={"#000000"}
                                    level={"L"}
                                    includeMargin={false}
                                    imageSettings={{
                                      src: '',
                                      height: 20,
                                      width: 20,
                                      excavate: true,
                                    }}
                                  />
              </div>
              <div className="text-xl font-normal mt-3 mb-3">If you want to transfer your ticket, please login using the same email with this order</div>
              {orderDetails.userEmail.toLowerCase() == (auth.currentUser?.email || "").toLowerCase() &&  
               <div style={{marginTop: '35px', marginBottom: '35px'}}>
               <div className="text-2xl font-semibold">Transfer Ticket</div>
               <FormItem label="Email Address">
                 <Input placeholder="" type="email" onChange={(e)=>{
                   const emailInput = e.target.value
                   setTicketTransferData({...ticketTransferData, email: emailInput})
                 }}/>
               </FormItem>
               <FormItem label="Name">
                 <Input placeholder="" type='text' onChange={(e)=>{
                   const nameInput = e.target.value
                   setTicketTransferData({...ticketTransferData, name: nameInput})
                 }}/>
               </FormItem>
               <ButtonPrimary className="mt-3" onClick={()=>{
                 //transfer ticket
                 ticketTransferAction(selectedTicket.id)
               }}>Transfer Ticket</ButtonPrimary>
             </div>}
             
      </div>
      
      }
      
      </Box>
    </Drawer>
  )

  useEffect(()=>{
    //check if guest
    loadUserOrderDetails()
  },[apiLoading])

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Congratulation
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {OrderTicketDetails}
        {OrderTicketPass}

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Your order confirmation</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={eventDetails.photoURL} />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {orderId}
                </span>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  {eventDetails.title}
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {eventDetails.description.substring(0,120)}
              </span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Date</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {orderDetails.dateSelected == "" ? eventDetails.startDateString : orderDetails.dateSelected}
                </span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Location</span>
                <span className="mt-1.5 text-lg font-semibold">{eventDetails.address.fullAddress}</span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Order Details</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Order ID</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {orderId}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Order Date</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {orderDate.toLocaleDateString('en')}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Total</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {paymentAmount.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})}
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Payment Method</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {paymentAmount == 0 ? 'FREE' : 'Credit Card'}
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Order Status</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {orderDetails.status == 'pending' ? 'Pending' : orderDetails.status == 'cancelled' ? 'Cancelled' : ''}
              </span>
            </div>
          </div>
        </div>
        { eventDetails.type != "tour" && <div className="space-y-6">
        <div id="ticketDetails">
        <div style={{marginTop: 45, marginBottom: 45, marginRight: 20}}>
        {userIsGuest.guest && <div>
          <h3 className="text-2xl font-semibold">Complete Registration</h3>
          <Alert severity="warning">You are on the guest account, please create your account so you can login to your account.</Alert>
          <div className="mt-5 mb-5">
          <FormItem label="Email Address">
            <Input placeholder="" type="email" value={userIsGuest.email} disabled/>
          </FormItem>
          <FormItem label="Password">
            <Input placeholder="Choose your password" onChange={(e)=>{
              const passwordEntered = e.target.value
              setUserIsGuest({...userIsGuest, password: passwordEntered})
            }} type="password"/>
          </FormItem>
          <div>
          <label className="block">
              <Checkbox className="text-neutral-800 dark:text-neutral-200" checked={true} defaultChecked={true}/>              
            <a target="_blank" href="/user-privacy.html">
              <span className="text-neutral-800 dark:text-neutral-200">agree to Event Go Privacy Policy</span>
            </a>    
            </label>
            <ButtonPrimary className="mt-5" onClick={()=>{
              //registration complete
              if (userIsGuest.email != "" && userIsGuest.password != ""){
                let authCred = EmailAuthProvider.credential(userIsGuest.email,userIsGuest.password)
                if (auth.currentUser != null){
                  linkWithCredential(auth.currentUser,authCred).then((cred) => {
                    window.location.reload()
                  }).catch((e) => {
                    console.log("Error: " + e)
                    alert("Unable to create your account, there's already another account with same email.")
                  })
                }
              }
              else{
                window.alert("Please enter email and password to continue")
              }
            }}>Complete Registration</ButtonPrimary>
          </div>
          </div>
          </div>}
        <h3 className="text-2xl font-semibold mt-8">Tickets ({tickets.length})</h3>
          { 
            tickets.map((ticketItm) => {
              return <div className="mt-5"><div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1"><a href={"https://evtgo.com/ticket-id/"+ticketItm.ticketId}>{ticketItm.ticketId}</a>
              <span><br/>Ticket: <b>{ticketItm.ticketName}</b><br/>Ticket Holder: <b>{ticketItm.userName}</b></span>
              {/* <br/><br/>
              <ButtonSecondary onClick={()=>{
                if (!ticketOpen){
                  setSelectedTicket(ticketItm)
                  setTicketOpen(true)
                }
                else{
                }
                
              }}>View Ticket</ButtonSecondary> */}
              {!screenshotTime && 
              <div className="flow-root mt-2">
              <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
              <span 
                className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer"
                onClick={()=>{
                  if (!ticketOpen){
                    setSelectedTicket(ticketItm)
                    setTicketOpen(true)
                  }
                  else{
                  }
                  
                }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
            />
          </svg>
          <span className="font-bold sm:block ml-2.5">Ticket Details</span>
        </span>
              </div>
              {/* <button className="font-bold mt-4" onClick={()=>{
                setSelectedTicket(ticketItm)
                setAddToWalletOpen(true)
              }}>Add to wallet</button> */}
            </div>
            }
              

              </span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
              <QRCodeCanvas
                                    id="qrCode"
                                    value={
                                      "https://tickets.evtgo.com/ticket-id/" + ticketItm.ticketId
                                    }
                                    size={128}
                                    bgColor={"#ffffff"}
                                    fgColor={"#000000"}
                                    level={"L"}
                                    includeMargin={false}
                                    imageSettings={{
                                      src: '',
                                      height: 20,
                                      width: 20,
                                      excavate: true,
                                    }}
                                  />
              </span>
            </div>
            </div>
            })
          }
          </div>
          </div>
        </div> }
        <div>
          <ButtonSecondary href={"/e/"+orderDetails.eventId}>View Event</ButtonSecondary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        {isLoading ? <div className="max-w-4xl mx-auto"><LoadingHeaderView/></div> : 
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
        }
      </main>
    </div>
  );
};

export default OrderConfirmationPage;
