import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { EventDataType, EventOrderType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface EventCardProps {
  className?: string;
  data?: EventOrderType;
  size?: "default" | "small";
}

const DEMO_DATA = {
  id: '',
  amount: 0,
  eventId: '',
  title: '',
  description: '',
  photoURL: '',
  orgId: '',
  paymentId: '',
  uniqueId: '',
  userEmail: '',
  userUID: '',
  date: '',
  status: '',
  type: '',
};

const EventOrderCard: FC<EventCardProps> = ({
  size = "default",
  className = "",
  data = DEMO_DATA,
}) => {
  const {
    id,
  title,
  description,
  amount,
  eventId,
  photoURL,
  paymentId,
  status,
  type,
  date,
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`EventOrderCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={[photoURL]}
          href={'/order-confirmation/'+id}
        />
        {/* <BtnLikeIcon isLiked={false} className="absolute right-3 top-3 z-[1]" /> */}
        {type == 'tour' && <SaleOffBadge desc={'Tour Tickets'} className="absolute left-3 top-3" />}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            Date: {date}
          </span>
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title == '' ? 'Event' : title}</span>
            </h2>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            Status: {status}
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            Total Paid: {amount.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})}
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            Date of Purchase: {date}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={'/order-confirmation/'+id}>{renderContent()}</Link>
    </div>
  );
};

export default EventOrderCard;
