import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

interface CSVInviteDialogProps {
  isOpen: boolean;
  showLoading: boolean;
  onClose: () => void;
  onSubmit: (formData: []) => void;
}


const CSVInviteDialog: React.FC<CSVInviteDialogProps> = ({
  isOpen,
  showLoading,
  onClose,
  onSubmit,
}) => {

  const defaultInviteTableHeaders = ["Email", "First Name", "Last Name", "Phone Number"];
  const defaultInviteDataFields = ["email", "first_name", "last_name", "phone"];
  const [inviteData, setInviteData] = useState([]);

  const validateEmail = (email:string) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const value = regex.test(email);
    return value;
  }

  const handleSubmit = ()=>{
    const data = inviteData
    setInviteData([])
    onSubmit(data as any)
  }

  const parseCsv = (content:any) => {
    const lines = content.split('\n');
    const result = [];

    const emails:String[] = [];

    for (let i = 1; i < lines.length; i++) {
      const values = lines[i].replace(/(\r\n|\n|\r)/g, "").trim().split(',');
      if (values.length > 0 && values[0].length > 0) {
        const acc:any = {}
        for(let j = 0; j < defaultInviteDataFields.length; j++){
          const header:String = defaultInviteDataFields[j]
          if(j === 0){
            const email = values[j]?.trim();
            if(email == null || !validateEmail(email) || emails.includes(email)){
              break
            }
            emails.push(email)
            acc['email'] = email
          }else{
            if(values.length > j){
              acc[header.trim()] = values[j]?.trim()
            }else{
              acc[header.trim()] = ''
            }
          }
        }
        if(Object.keys(acc).length !== 0){
          result.push(acc);
        }
      }
    }
    setInviteData(result as any); // 更新数据
  };

  const handleFileUpload = (event:any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target!.result;
        parseCsv(content);
      };
      reader.readAsText(file);
    }
  };

  return  <Dialog open={isOpen} onClose={()=>{}} className="relative z-50">
  {/* 背景遮罩 */}
  <div className="fixed inset-0 bg-black/30" aria-hidden="true"></div>

  {/* 面板内容 */}
  <div className="fixed inset-0 flex items-center justify-center p-4">
<Dialog.Panel className="relative w-1/2 h-1/2 bg-white rounded-lg p-6 shadow-lg flex flex-col">
<Dialog.Title className="text-lg font-bold mb-4">Invite {inviteData.length > 0 ? '('+inviteData.length+')':''}</Dialog.Title>
{/* CSV 数据展示 */}
<div className="flex-grow overflow-auto border rounded-md p-2">
<table className="w-full table-auto text-sm border-collapse border border-gray-300">
  <thead>
    <tr>
      {(defaultInviteTableHeaders).map((key) => (
        <th
          key={key}
          className="border border-gray-300 px-2 py-1 text-left font-medium bg-gray-100"
        >
          {key}
        </th>
      ))}
    </tr>
  </thead>
  <tbody>
    {inviteData.length > 0 ? (
      inviteData.map((row, index) => (
        <tr key={index} className="even:bg-gray-100">
          {Object.values(row).map((value:any, idx) => (
            <td
              key={idx}
              className="border border-gray-300 px-2 py-1"
            >
              {value}
            </td>
          ))}
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={defaultInviteTableHeaders.length} className="text-center py-4 text-gray-500">
          No data uploaded
        </td>
      </tr>
    )}
  </tbody>
</table>
</div>
<div className="mt-4 flex flex-row justify-between align-bottom">
   {/* 上传 CSV 文件 */}
    <div className="flex flex-col">
    <input
      type="file"
      accept=".csv"
      className="focus:outline-none"
      onChange={handleFileUpload}
            />
    <span className="text-gray-500 italic">should contains at latest email, like evento@evento.io,xxx,xxx,xxx,xxx</span>
    </div>
    {/* 操作按钮 */}
    <div className="flex flex-row gap-2">
      <ButtonSecondary onClick={onClose} disabled={showLoading}>
        Cancel
      </ButtonSecondary>
      <ButtonPrimary
        loading={showLoading}
        onClick={() => {
            handleSubmit()          
        }}
      >
        Confirm
      </ButtonPrimary>
    </div>
</div>
</Dialog.Panel>
</div>
</Dialog>
}

export default CSVInviteDialog;
