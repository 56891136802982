import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { EventDataType, EventManagementDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface EventCardProps {
  className?: string;
  data?: EventManagementDataType;
  size?: "default" | "small";
}

const DEMO_DATA = {
    id: '',
  title: '',
  description: '',
  startDateString: '',
  endDateString: '',
  address: '',
  photoURLs: [],
  photoURL: '',
  viewCount: 0,
  price: 0,
  location: '',
  status: ''
};

const EventManagementCard: FC<EventCardProps> = ({
  size = "default",
  className = "",
  data = DEMO_DATA,
}) => {
  const {
    id,
  title,
  description,
  address,
  startDateString,
  endDateString,
  photoURLs,
  photoURL,
  location,
  viewCount,
  price,
  status,
  } = data;

  const renderSliderGallery = () => {
    let statusStr = status == 'live' ? "Live" : status == 'draft' ? "Draft" : status == 'private' ? "Private" : "Closed"
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`ManageEventCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={photoURLs}
          href={'/manage-event/'+id}
        />
        {/* <BtnLikeIcon isLiked={false} className="absolute right-3 top-3 z-[1]" /> */}
        <Badge name={statusStr} color={'red'} className="absolute left-3 top-3" />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {startDateString == "" ? "Past" : startDateString}
          </span>
          <div className="flex items-center space-x-2">
            {/* <Badge name="ADS" color="green" /> */}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{address}</span>
          </div>
        </div>
        

      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={'/manage-event/'+id}>{renderContent()}</Link>
    </div>
  );
};

export default EventManagementCard;
