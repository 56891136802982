import React, { FC, Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { EventOrderType, EventTicketType } from "data/types";
import { QRCodeCanvas } from 'qrcode.react'
import FormItem from "containers/PageAddListing1/FormItem";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select"
import { DocumentData } from "firebase/firestore";
import { EventPriceDataType } from "data/types";

interface ModalOrderSaveTicketsProps {
  renderChildren?: (p: { openModal: () => void }) => React.ReactNode;
  eventId?: string,
  eventName?: string,
  eventData?: DocumentData,
  userUID: string,
  eventPriceIds?: string[],
  eventPrices?: {[key: string]: EventPriceDataType},
}

const ModalAddAttendee: FC<ModalOrderSaveTicketsProps> = ({ eventId='', eventName='', userUID='', eventPriceIds=[], eventData={prices: {}}, eventPrices={}, renderChildren }) => {
  const [showModal, setShowModal] = useState(false);
  const [invitationData, setInvitationData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    quantity: '1',
    priceId: '',
  })
  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  //
  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return renderChildren ? (
      renderChildren({ openModal })
    ) : (
      <button onClick={openModal}>Select Date</button>
    );
  };


  async function sendOrderInvitation(){
    const url = `https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPICreateFreeTicketsByAdmin?event_id=${eventId || ""}&first_name=${invitationData.firstName}&last_name=${invitationData.lastName}&email=${invitationData.email.toLowerCase()}&price_id=${invitationData.priceId}&quantity=${invitationData.quantity}&org_user_uid=${userUID}`
    fetch(url, {
      headers: {
          "Content-Type": "application/json",
        }
      }).then((data)=>{
        closeModal()
        setInvitationData({
          firstName: '',
        lastName: '',
        email: '',
       quantity: '1',
        priceId: '',
        })
        alert('New Order created')
        window.location.reload()
      }).catch((e)=>{
    })
  }

  return (
    <>
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-hidden" style={{margin: 16}}>
                        <h1 className="font-semibold text-2xl">Invite New Guests</h1>
                        <div className="mt-3" style={{marginTop: 25,}}>
                        <FormItem className="mt-2 mb-2" label="First Name">
                            <Input defaultValue={invitationData.firstName} onChange={(e)=>{
                              const nameEntered = e.target.value
                              setInvitationData({...invitationData, firstName: nameEntered})
                            }}/>
                        </FormItem>
                        <FormItem className="mt-2 mb-2" label="Last Name">
                          <Input defaultValue={invitationData.lastName} onChange={(e)=>{
                              const nameEntered = e.target.value
                              setInvitationData({...invitationData, lastName: nameEntered})
                            }}/>
                        </FormItem>
                        <FormItem className="mt-2 mb-2" label="Email">
                          <Input type={'email'} defaultValue={invitationData.email} onChange={(e)=>{
                              const nameEntered = e.target.value
                              setInvitationData({...invitationData, email: `${nameEntered}`})
                            }}/>
                        </FormItem>
                        <FormItem className="mt-2 mb-2" label="Ticket Type">
                        <Select onChange={(e)=>{
                          let selectedId = e.target.value || ''
                          setInvitationData({...invitationData, priceId: selectedId})
                          }}>
                            <option value={''}>{'--- select ticket type ---'}</option>
                            {
                              eventPriceIds.map((itm)=>{
                                let priceDesc = ""
                                if (Object.keys(eventPrices).includes(itm)){
                                  priceDesc = eventPrices[itm].priceDescription
                                }
                                return <option value={itm}>{priceDesc}</option>
                              })
                            }
                          </Select>
                        </FormItem>
                        <FormItem className="mt-2 mb-2" label="Quantity">
                        <Select onChange={(e)=>{
                          let selectedId = e.target.value || '1'
                          setInvitationData({...invitationData, quantity: selectedId})
                          }}>
                            <option value={'1'}>{'1'}</option>
                            <option value={'2'}>{'2'}</option>
                            <option value={'3'}>{'3'}</option>
                            <option value={'4'}>{'4'}</option>
                            <option value={'5'}>{'5'}</option>
                            <option value={'6'}>{'6'}</option>
                            <option value={'7'}>{'7'}</option>
                            <option value={'8'}>{'8'}</option>
                            <option value={'9'}>{'9'}</option>
                            <option value={'10'}>{'10'}</option>
                          </Select>
                        </FormItem>
                        </div>
                        
                    </div>

                    <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                      <button
                        type="button"
                        className="underline font-semibold flex-shrink-0"
                        onClick={() => {}}
                      >
                        Clear data
                      </button>
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          //check
                          if (invitationData.email != "" && invitationData.email.includes('.') && invitationData.email.includes('@') && invitationData.firstName != "" && invitationData.lastName != "" && invitationData.priceId != ""){
                            sendOrderInvitation()
                          }
                          else{
                            alert('Please fill all required fields')
                          }
                        }}
                      >
                        Invite
                      </ButtonPrimary>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalAddAttendee;
