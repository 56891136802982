import React, { useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';

// 定义 props 的类型
interface ManualInviteDialogProps {
  isOpen: boolean;
  showLoading: boolean;
  onClose: () => void;
  onSubmit: (formData: { email: string; firstName: string; lastName: string; phone: string }) => void;
}

const defaultHeaders = [
  { label: "Email", name: "email", required: true },
  { label: "First Name", name: "firstName", required: false },
  { label: "Last Name", name: "lastName", required: false },
  { label: "Phone", name: "phone", required: false },
];

const ManualInviteDialog: React.FC<ManualInviteDialogProps> = ({ isOpen, showLoading, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
  });

  const [errors, setErrors] = useState({
    email: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'email') {
      setErrors({ ...errors, email: value.trim() === '' || validateEmail(formData.email) });
    }
  };

  const validateEmail = (email:string) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const value = regex.test(email);
    return !value;
  };

  const handleSubmit = () => {
    // Check for validation errors
    const newErrors = {
      email: formData.email.trim() === '' || validateEmail(formData.email),
    };

    setErrors(newErrors);

    if (!newErrors.email) {
        const curData = formData;
        setFormData({email: '',
            firstName: '',
            lastName: '',
            phone: ''})
      onSubmit(formData); // Call the onSubmit callback passed as a prop
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
        <h2 className="text-xl font-semibold mb-4">Invite Manualy</h2>
        
        <div className="flex-grow overflow-auto space-y-4">
          {defaultHeaders.map((header) => (
            <div key={header.name} className="flex flex-col">
            <div className='flex flex-row align-middle'>
            <label htmlFor={header.name} className="text-sm font-medium text-gray-700">
                {header.label}
              </label>
              {header.required && <span className='text-red-500'>*</span>}
            </div>
              <input
                type="text"
                id={header.name}
                name={header.name}
                value={formData[header.name as keyof typeof formData]}
                onChange={handleChange}
                className={`mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 ${
                  errors[header.name as keyof typeof errors] ? 'border-red-500' : 'focus:ring-blue-500'
                }`}
                required={header.required}
              />
              {errors[header.name as keyof typeof errors] && <p className="text-xs text-red-500">please input valid {header.name}</p>}
            </div>
          ))}
        </div>

        <div className="flex justify-end gap-4 mt-6">
          <ButtonSecondary
            disabled={showLoading}
            onClick={onClose}
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary loading={showLoading}
            onClick={handleSubmit}
          >
            Submit
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default ManualInviteDialog;
