import React, { FC, Fragment, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom"
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import { Helmet } from "react-helmet-async";
import { EventPriceDataType, EventDataType } from "data/types";
import LikeSaveBtns from "components/LikeSaveBtns";
import { useLocation, useNavigate } from "react-router-dom";
import { Amenities_demos, PHOTOS } from "./constant";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import EventDetailPagetLayout from "../ListingDetailPage/EventDetailPageLayout";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import GallerySlider from "components/GallerySlider/GallerySlider";
import PhoneInput from 'react-phone-number-input'
import { RWebShare } from "react-web-share";
import { auth, fs } from "firebaseSetup"
import { AlbumOutlined, CollectionsOutlined, PhotoAlbumRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateEventAlbum } from "store";
import { EventFormQuestionType } from "data/types";
import { Paper, Typography,  Skeleton, Stack, Card, CardHeader, CardContent, CardMedia, CardActions, IconButton, Button, Drawer, Box, Alert } from '@mui/material'
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import { buttonClickRecorded, eventGoFormSubmission, eventGoRequestSubmission } from "hooks/EventGoAPI";
import { isMobile } from "react-device-detect";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import Select from "shared/Select/Select"


const MutipleImageGallery = ({ mainImage = '', topImage = '', bottomImage = '', showMoreIcon=false }) => {
  return (
    <div className="relative grid grid-cols-4 gap-1 sm:gap-2 w-full rounded-md sm:rounded-xl h-full">
      <div className="col-span-3 rounded-md sm:rounded-xl overflow-hidden bg-white-200">
        <img className="object-cover rounded-md sm:rounded-xl w-full h-full" src={mainImage} alt="Main" />
      </div>
      <div className="col-span-1 grid grid-rows-5 gap-1 overflow-hidden">
        <div className="row-span-3 rounded-md sm:rounded-xl bg-white-200 overflow-hidden">
          <img className="object-cover rounded-md sm:rounded-xl w-full h-full" src={topImage} alt="Top" />
        </div>
        <div className="relative row-span-2 rounded-md sm:rounded-xl bg-white-200 overflow-hidden">
          <img className="object-cover rounded-md sm:rounded-xl w-full h-full" src={bottomImage} alt="Bottom" />
          {showMoreIcon?<div className="absolute bottom-2 right-2 text-white text-xl">
            <CollectionsOutlined className="w-10 h-10 lg:w-14 lg:h-14"></CollectionsOutlined>
          </div>:<div></div>}
          
        </div>
      </div>
    </div>
  );
};


const EventDetailPageContainer: FC<{}> = () => {
  //

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  let [eventPrices, setEventPrices] = useState<EventPriceDataType[]>([])
  const [eventPriceRange, setEventPriceRange] = useState<string>('')
  const [eventLoading, setEventLoading] = useState<boolean>(true)
  const [eventIsPast, setEventIsPast] = useState<boolean>(false)
  const [eventData, setEventData] = useState({
    title: 'Loading Event...',
    description: '',
    descriptions: {
      en: [''],
      zh: [''],
    },
    startDateString: '',
    endDateString: '',
    id: '',
    orgId: '',
    agenda: [],
    address: {
      name: '',
      fullAddress: '',
      street: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      unit: '',
      online: false,
      lat: '0.0',
      lng: '0.0'
    },
    price: 0,
    prices: {},
    photoURL: '',
    location: '',
    photoURLs: [],
    priceDiscount: null,
    viewCount: 0,
    type: 'event',
    refundPolicy: "No Cancellation",
    status: '',
    tags: [''],
    shareURL: '',
    terms: {
      text: '',
      texts: [],
    },
    user: {
      userName: '',
      userOrg: '',
      uid: '',
      email: '',
      imageURL: '',
    },
    options: {
      poll: false,
      draw: false,
      namecard: false,
      review: false,
      comment: true,
      picture: true,
      guest: false,
      request: false,
      max: 150
    }
  })

  const thisPathname = useLocation().pathname;
  const router = useNavigate();

  const { id } = useParams()
  const [searchParams] = useSearchParams();
  //if it's using coupon
  const couponCode = searchParams.get('couponCode') || "";
  //if is coming from request
  const requestedId = searchParams.get('requestId') || "";
  //if using secret view code, adding to view data
  const viewT = searchParams.get('viewType') || "";
  
  const [eventViewId, setEventViewId] = useState('')
  const [eventId, setEventId] = useState(id || "")
  const [eventCart, setEventCart] = useState<EventPriceDataType[]>([])
  const [shoppingCart, setShoppingCart] = useState({})
  const [shoppingCartRuleAppliedPriceIds, setShoppingCartRuleAppliedPriceIds] = useState([])

  const dispatch = useDispatch()

  const [userCoupon, setUserCoupon] = useState({
    coupon: '',
    couponType: '',
    promotionCode: '',
    amount: 0,
  })

  async function eventPromoValidation(couponCode:string){
    const dataBody = `coupon=${couponCode}&event_id=${id || ''}`;
    fetch("https://v1eventsapiapplycoupon-650318705789.us-central1.run.app?"+dataBody,{
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', }
    }).then((response) => {
      response.json().then((data) => {
        var dataS = {
          success: false, 
          couponType: "",
          promotionCode: '',
          couponCode: '',
          amount: 0}
        dataS = data
        if (dataS.success){
          if (dataS.couponType == 'FREE'){
            //FREE Order
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
              promotionCode: '',
              amount: 0,
            })
          }
          else if (dataS.couponType == "PERCENTAGE"){
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
              promotionCode: dataS.promotionCode,
              amount: dataS.amount,
            })
          }
          else if (dataS.couponType == "AMOUNT"){
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
              promotionCode: dataS.promotionCode,
              amount: dataS.amount,
            })
          }
          else{
            setUserCoupon({
              coupon: "",
              couponType: "",
              promotionCode: "",
              amount: 0,
            })
            window.alert('Coupon is invalid')
          }
        }
        else{
          setUserCoupon({
            coupon: "",
            couponType: "",
            promotionCode: "",
            amount: 0,
          })
        }
      })
    })
  }


  const fetchEventDetails = () => {
    const eventId = id || ""
    fetch(`https://us-central1-blink-574af.cloudfunctions.net/v1EventsAPIFetchEventDetails?event_id=${eventId}&viewType=${viewT}`, {
      headers: {
        "Content-Type": "application/json",
      },

    }).then((response) => {
      const responseTask = response.json();
      responseTask.then((data) => {
        let dataItm = {
          event: eventData,
          viewId: "",
          tickets: {},
          organizer: {}, 
          status: "failure"}
          
          dataItm = data
          if (dataItm.status == 'success'){
            if (dataItm.viewId != null && dataItm.viewId != ""){
              localStorage.setItem("viewId",dataItm.viewId)
            }
            const eventItm = dataItm.event
            var eventPhotoURLs
            if (eventItm.photoURLs.length == 0){
              eventPhotoURLs = [eventItm.photoURL]
            }else{
              eventPhotoURLs = eventItm.photoURLs
            }
            let refund = ""
            if (eventItm.refundPolicy != null){
              refund = eventItm.refundPolicy
            }
            let tags:string[] = []
            if (eventItm.tags != null){
              tags = eventItm.tags
            }
            let eOptions = {
              poll: false,
              draw: false,
              namecard: false,
              review: false,
              comment: true,
              picture: true,
              guest: false,
              request: false,
              max: 500
            }
            if (eventItm.options != null){
              let guestTicket = false
              let requestJoin = false
              if (eventItm.options.guest != null){
                guestTicket = eventItm.options.guest
              }
              if (eventItm.options.request != null){
                requestJoin = eventItm.options.request
              }
              eOptions = {
                poll: false,
                draw: false,
                namecard: false,
                review: false,
                comment: true,
                picture: true,
                guest: guestTicket,
                request: requestJoin,
                max: eventItm.options.max
              }

            }
            if (eventItm.prices != null){
              let pricesArray: EventPriceDataType[] = []
              pricesArray = Object.values(eventItm.prices)
              let finalPriceArray = pricesArray.sort((a,b)=>{
                return a.order - b.order
              })
              console.log(finalPriceArray)
              setEventPrices(finalPriceArray)
              if (pricesArray.length == 1){
                //
                if (eventItm.priceDiscount != null){
                  const priceItm = pricesArray[0].price.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})
                  setEventPriceRange(`${priceItm}`)
                }
                else{
                  const priceItm = pricesArray[0].price.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})
                  setEventPriceRange(`${priceItm}`)
                }
              }
              else if (pricesArray.length == 0){
                setEventPriceRange(`FREE`)
              }
              else{
                let newPrices = pricesArray
                
                let highPrice = 0
                let lowPrice = 9999999
                newPrices.forEach((priceItm) => {
                  if (priceItm.price > highPrice){
                    highPrice = priceItm.price
                  }
                  if (priceItm.price < lowPrice) {
                    lowPrice = priceItm.price
                  }
                })
                console.log(`High: ${highPrice} Low: ${lowPrice}`)
                if (eventItm.priceDiscount != null){
                  var priceD = 0
                  priceD = eventItm.priceDiscount
                  setEventPriceRange(`${priceD.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})} - ${highPrice.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})}`)
                }
                else{
                  setEventPriceRange(`${eventItm.price.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})} - ${highPrice.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})}`)
                }
                
                
              }
            }


            let terms = {text: '', texts: []}
            let shareURL = window.location.origin + '/e/' + eventId
            if (eventItm.terms != null){
              terms = eventItm.terms
            }
            if (eventItm.shareURL != null){
              if (eventItm.shareURL != ""){
                shareURL = eventItm.shareURL
              }
            }
            //past event
            if (eventItm.endDateString != null){
              let endDateS = eventItm.endDateString
              let dateNow = new Date()
              let endedD = new Date(endDateS)
              if (dateNow > endedD) {
                setEventIsPast(true)
              }
              else{
                setEventIsPast(false)
              }
            }
            else{
              setEventIsPast(true)
            }
            let enDesc: string[] = []
            let zhDesc: string[] = []
            if (eventItm.descriptions != null){
              if (eventItm.descriptions.en != null){
                enDesc = eventItm.descriptions.en
              }
              if (eventItm.descriptions.zh != null){
                zhDesc = eventItm.descriptions.zh
              }
            }
            if (enDesc.length == 0){
              if (eventItm.description != null && eventItm.description != ""){
                if (eventItm.description.includes('\n')){
                  enDesc = eventItm.description.split('\n')
                }
              }
              setEventIsPast(true)
            }
            let priceItm = 0
            if (eventItm.price != null){
              priceItm = eventItm.price
            }
            setEventData({...eventItm, 
              refundPolicy: refund, 
              tags: tags, 
              terms: terms, 
              shareURL: shareURL, 
              options: eOptions, 
              price: priceItm,
              descriptions: {
                zh: zhDesc, 
                en: enDesc}})
            dispatch(updateEventAlbum(eventPhotoURLs))
            
            let vId = ""
  
            if (dataItm.viewId != ""){
              setEventViewId(dataItm.viewId)
              vId = dataItm.viewId
            }
            
            //apply coupon
            if (couponCode != ""){
              eventPromoValidation(couponCode)
            }
            if (eOptions.request){
              //load forms
              getFormsAndTicketsData(vId)
            }
            setEventLoading(false)

            //requestedId
            if (requestedId != "" && eOptions.request){
              //go to validation
              loadEventRequest(vId)
            }
          }
          else{
            window.location.assign('/')
          }
          
      })
    })

  }

  useEffect(()=>{
    fetchEventDetails()
  },[])

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const getTicketAction = () => {
    const eventView = JSON.stringify({"eventId": (id || ""), "viewId": eventViewId, "coupon": couponCode, "type": eventData.type})
    localStorage.setItem("eventView",eventView)
    
    let redirectLink = ""
    if (eventData.shareURL != ""){
      if (eventData.shareURL.includes('evtgo.com') || eventData.shareURL.includes('eventgo.ca') || eventData.shareURL.includes('localhost:3000')){
        //go to link
      }
      else if (eventData.shareURL.includes('VIEWONLY')){
        //go to link
        redirectLink = 'VIEWONLY'
      }
      else {
        redirectLink = eventData.shareURL
      }
    }
    else{
      
    }
    
    if (redirectLink == ""){
      //go to our checkout
      if (eventData.options.request){
        //request first
        setRequestOpen(true)
      }
      else{
        //other

        if (eventData.options.guest){
          //can get guest checkout
          buttonClickRecorded(`Event Details - Guest - ${eventId}`,'')
          setEventViewId('')
          if (eventData.type == "tour"){
            window.location.assign("/tour-order/"+ (id || "") + "/" + eventViewId)
          }
          else{
            window.location.assign("/event-order/"+ (id || "") + "/" + eventViewId)
          }
          
        }
        else{
          //only registered user
          if (auth.currentUser != null){
            buttonClickRecorded(`Event Details - Registered User - ${eventId}`,'')
            setEventViewId('')
            if (eventData.type == "tour"){
              window.location.assign("/tour-order/"+ (id || "") + "/" + eventViewId)
            }
            else{
              window.location.assign("/event-order/"+ (id || "") + "/" + eventViewId)
            }
          }
          else{
            const eventRedirection = JSON.stringify({"eventId": (id || ""), "viewId": eventViewId, "coupon": couponCode, "type": eventData.type})
            localStorage.setItem("login_redirect",eventRedirection)
            buttonClickRecorded(`Event Details - Signup - ${eventId}`,'')
            window.location.assign('/signup')
          }
  
        }

      }
      
      
    }
    else if (redirectLink == "VIEWONLY"){

    }
    else{
      //redirect to other platform
      buttonClickRecorded(`Event Details - Redirect - ${eventId} - ${redirectLink}`,'')
      window.location.assign(redirectLink)
    }
    
    
  }

  function getFormsAndTicketsData(viewId:string){
    fetch("https://us-central1-blink-574af.cloudfunctions.net/v1EventsAPIGetTicketData?event_id="+(id || "")+"&view_id="+(viewId || ""),{
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      const responseTask = response.json();
      responseTask.then((data) => {
       
        var formQuestionns: EventFormQuestionType[] = []
        if (data.form != null){
          if (data.form.data != null){
            formQuestionns = data.form.data
          }
        }
        setFormQuestions(formQuestionns)
       
      })
    })

  }

  async function loadEventRequest(vId:string){
    if (requestedId != "" && requestedId != null){
    fetch("https://us-central1-blink-574af.cloudfunctions.net/v1EventsAPIFetchRequestInfo?event_id="+(id || "")+"&request_id="+requestedId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    })
    .then(response => response.json())
    .then((data) => {
      let dataResult = {
        success: false,
        status: 'failure',
        request: {
          firstName: '',
          lastName: '',
          email: '',
          status: '',
        },
      }
      dataResult = data
      if (dataResult.success){
        if (Object.keys(dataResult.request).length > 0 && dataResult.request.status == 'approved'){

          const eventView = JSON.stringify({
            "eventId": (id || ""), 
            "viewId": vId, 
            "coupon": couponCode,
            "requestId": requestedId, 
            "type": eventData.type})
          localStorage.setItem("eventView",eventView)
          window.location.assign("/event-order/"+ (id || "") + "/" + vId + "?requestId="+requestedId)
        }
        else{
          window.location.href = "/e/"+id
        }
      }
      else{
        window.location.href = "/e/"+id
      }
    })
      
    }
    
  }

  async function uploadImage(file:File,index:number) {
    fetch('https://r2-worker.sam-12e.workers.dev/', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': file.type
      },
      body: file
    })
      .then((response) => response.text())
      .then((data) => {
        const downloadURL = "https://pub-e888c0363b7c412caf77f22ba84fcb86.r2.dev/" + data
        let allQues = formQuestions
        if (index >= 0 && index < formQuestions.length){
          let cQues = allQues[index]
          cQues.value = downloadURL
          allQues[index] = cQues
          setFormQuestions(allQues)
          setRequestMessage("Photo Uploaded: " + cQues.title)
        }                  
        
      }) // 
      .catch((error) => console.error(error));
  }

  interface FormAnswerStyle {
    title: string;
    value: string;
    id: string;
  }

  async function handleEventRequestSubmit(
    userEmail:string,
    firstName:string,
    lastName:string,
    priceId:string,
    quantity:number,
    userUID:string){

    var formAnswers: FormAnswerStyle[] = []
    formQuestions.forEach((eventQItm) => {
      let formV:string = ""
      if (eventQItm.value != null){
        formV = eventQItm.value
      }
      formAnswers.push(
        {
        title: eventQItm.title, 
        value: formV,
        id: eventQItm.id,
      })
    })

    console.log('Form Submission: ')
    const sId = await eventGoFormSubmission(
      id || "", 
      userEmail, 
      firstName,
      lastName, 
      formAnswers)

    const ua = window.navigator.userAgent.toLowerCase();
    console.log('Request Submission')
    const requestId = await eventGoRequestSubmission(
      id || "",
      userEmail,
      firstName,
      lastName,
      ua,
      sId,
      formAnswers,
      priceId,
      quantity,
      userUID,
    )
    console.log('Handle Request')
    setRequestLoading(false)
    setRequestOpen(false)
    window.alert("Form submitted successfully")
    createEventRequestNotification(requestId)
  }

  

  async function createEventRequestNotification(requestId:string){
      const dataBody = `request_id=${requestId}&event_id=${id || ''}`;
  fetch("https://eventapineweventrequestjoinrequest-verqhozorq-uc.a.run.app?"+dataBody,{
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*', }
  }).then((response) => {
    window.location.reload()
  })

  }


  const [requestOpen, setRequestOpen] = useState(false)
  const [requestMessage, setRequestMessage] = useState("")
  const [formQuestions, setFormQuestions] = useState<EventFormQuestionType[]>([])
  const [requestBasicInfo, setRequestBasicInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    priceId: "",
    quantity: 0,
  })
  const [requestLoading, setRequestLoading] = useState<boolean>(false)
  const RequestToJoinDrawer = (
    <Drawer open={requestOpen} onClose={()=>{ setRequestOpen(false)}}>
       <Box 
      role="presentation" 
      style={{width: isMobile ? '100vw' : '50vw'}}
      >
        <div className="relative space-y-8" style={{margin: '16px'}}>
        <ButtonSecondary className="ml-3" onClick={()=>{
          setRequestOpen(false)
        }}>Back</ButtonSecondary>
      </div>

      {requestMessage && <Alert severity="success">{requestMessage}</Alert> }

      <div className="relative space-y-2" style={{margin: '16px'}}>
          <div className="text-2xl font-bold">Request to Join Event</div>
          <br/>
          <div className="text-xl font-semibold">Basic Information</div>
          <br/>
          <div className="space-y-1 mt-3 mb-2">
            <Label className={"text-black"}>Your Email Address *</Label>
            <Input type={"email"} onChange={(e)=>{
              const enteredData = e.target.value
              setRequestBasicInfo({...requestBasicInfo, email: enteredData})
            }}/>
          </div>
          <div className="space-y-1 mt-3 mb-2">
            <Label className={"text-black"}>Your First Name *</Label>
            <Input type={"text"} onChange={(e)=>{
              const enteredData = e.target.value
              setRequestBasicInfo({...requestBasicInfo, firstName: enteredData})
            }}/>
          </div>
          <div className="space-y-1 mt-3 mb-2">
            <Label className={"text-black"}>Your Last Name *</Label>
            <Input type={"text"} onChange={(e)=>{
              const enteredData = e.target.value
              setRequestBasicInfo({...requestBasicInfo, lastName: enteredData})
            }}/>
          </div>

          <div className="space-y-1 mt-3 mb-2">
            <Label className={"text-black"}>Select Tickets *</Label>
            {eventPrices.filter((ticketItm)=>{return requestBasicInfo.priceId == "" || ticketItm.id == requestBasicInfo.priceId}).map((priceItm)=>{
              return <div><NcInputNumber
              className="w-full mt-6"
              orderMin={priceItm.min}
              defaultValue={0}
              max={priceItm.max}
              min={0}
              onChange={(value)=>{
                if (value > 0){
                  setRequestBasicInfo({...requestBasicInfo, priceId: priceItm.id, quantity: value})
                }
                else{
                  setRequestBasicInfo({...requestBasicInfo, priceId: '', quantity: 0})
                }
              }}
              label={priceItm.price.toLocaleString('en-US',{style: 'currency', currency: priceItm.priceCurrency})}
              desc={priceItm.priceDescription}
              /></div>
            })}
            
            
          </div>

          

          {/* <div className="space-y-1 mt-3 mb-2">
            <Label className={"text-black"}>Select Ticket Type *</Label>
            <Select onChange={(e)=>{
                const pId = e.target.value || ""
                setRequestBasicInfo({...requestBasicInfo, priceId: pId})
            }}>
              <option value={''}>{'--- select option ---'}</option>
            {
              eventPrices.map((itm)=>{
                return <option value={itm.id}>{itm.priceDescription}</option>
              })
            }
              </Select>
          </div> */}



          {/* <div className="space-y-1 mt-3 mb-2">
            <Label className={"text-black"}>Ticket Quantity *</Label>
            <Select onChange={(e)=>{
                const quantity = e.target.value || ""
                setRequestBasicInfo({...requestBasicInfo, quantity: Number(quantity)})
            }}>
              <option value={''}>{'--- select option ---'}</option>
              <option value={'1'}>{'1'}</option>
              <option value={'2'}>{'2'}</option>
            {
              
            }
              </Select>
          </div> */}

          <br/>
          {formQuestions.length > 0 && <div>
            <div className="text-xl font-semibold">Additional Questions</div>
          <br/></div>}
          {
                    formQuestions.map((ques,index) => {
                      let labelTextStyle = "text-black"
                      if (ques.optionType == "options"){
                        //select
                        return <div className="space-y-1 mt-3">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <br/>
                          <Label>{ques.placeholder}</Label>
                          <Select onChange={(e)=>{
                            let allQues = formQuestions
                            let cQues = ques
                            cQues.value = e.target.value || ""
                            allQues[index] = cQues
                            setFormQuestions(allQues)
                          }}>
                          <option value={''}>{'--- select option ---'}</option>
                            {
                              ques.options.map((itm)=>{
                                return <option value={itm}>{itm}</option>
                              })
                            }
                          </Select>
                          </div>
                      }
                      else if (ques.optionType == "text"){
                        if (ques.valueType == "phone"){
                          return <div className="space-y-1 mt-3">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <br/>
                          <Label>{ques.placeholder}</Label>
                          <PhoneInput
                                  placeholder={ques.title}
                                  value={""}
                                  defaultCountry="CA"
                                  onChange={(e) => {
                                    let allQues = formQuestions
                                    let cQues = ques
                                    cQues.value = e || ""
                                    allQues[index] = cQues
                                    setFormQuestions(allQues)
                                  }}/>
                          </div>
                        }
                        else{
                          return <div className="space-y-1 mt-3">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <br/>
                          <Label>{ques.placeholder}</Label>
                          <Input type={'text'} required={ques.required} defaultValue="" onChange={(e)=>{
                            let allQues = formQuestions
                            let cQues = ques
                            cQues.value = e.currentTarget.value
                            allQues[index] = cQues
                            setFormQuestions(allQues)
                          }}/>
                          </div>
                        }
                        
                      }
                      else if (ques.optionType == "file"){
                        return <div className="space-y-1 mt-3">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <br/>
                          <Label>{ques.placeholder}</Label>
                          <div>
                          {ques.value != "" &&  <img src={ques.value} width="100%" height="350px" />}
                          <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>Upload File</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        onChange={(e)=>{
                          const files = e.currentTarget.files
                            if (files && files.length > 0){
                              uploadImage(files[0],index)
                            }
                          }}
                      />
                      </label>
                      </div>
                          
                          </div>
                          
                          </div>
                      }
                      else if (ques.optionType == "textarea"){
                        return <div className="space-y-1 mt-3">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <br/>
                          <Label>{ques.placeholder}</Label>
                          <Textarea required={ques.required} rows={5} onChange={(e)=>{
                            let allQues = formQuestions
                            let cQues = ques
                            cQues.value = e.currentTarget.value
                            allQues[index] = cQues
                            setFormQuestions(allQues)
                          }}
                          />
                          </div>
                      }
                    })
                  }

<div className="mt-5">
<ButtonPrimary className="mt-5" disabled={requestLoading} loading={requestLoading} onClick={()=>{
  //handle request check
  setRequestLoading(true)

  var basicValidation = false
  var formValidation = false
  if (
    requestBasicInfo.firstName != "" && 
    requestBasicInfo.lastName != "" && 
    requestBasicInfo.email != "" && 
    requestBasicInfo.email.includes("@") && 
    requestBasicInfo.email.includes(".") &&
    requestBasicInfo.priceId != "" &&
    requestBasicInfo.quantity >= 1){
      basicValidation = true
    }

    let notValidatedFields: string[] = []
    let notValidatedTitles: string[] = []
    

  formQuestions.forEach((formItm) => {
    if (formItm.required && (formItm.value == "" || formItm.value == null)){
      notValidatedFields.push(formItm.id)
      notValidatedTitles.push(formItm.title)
    }
    else{

    }
  })
  if (notValidatedFields.length == 0){
    formValidation = true
  }
  
  //form validation
  if (basicValidation){
    if (formValidation){
      const userUID = auth.currentUser?.uid || ""
      handleEventRequestSubmit(
        requestBasicInfo.email.toLowerCase(),
        requestBasicInfo.firstName,
        requestBasicInfo.lastName,
        requestBasicInfo.priceId,
        requestBasicInfo.quantity,
        userUID)
    }
    else{
      setRequestLoading(false)
      setRequestMessage(`Please make sure you filled required fields\n${notValidatedTitles.join('\n')}`)
      alert(`Please make sure you filled required fields:\n${notValidatedTitles.join('\n')}`)
    }
  }
  else{
    setRequestLoading(false)
    setRequestMessage("Please make sure you entered correct personal information")
    alert("Please make sure you entered correct personal information")
  }
  

  // if (basicValidation && formValidation){
  //   handleEventRequestSubmit(
  //     requestBasicInfo.email.toLowerCase(),
  //     requestBasicInfo.firstName,
  //     requestBasicInfo.lastName)
  // }
  // else{
  //   if (!basicValidation){
  //     //
  //     setRequestLoading(false)
  //     setRequestMessage("Please make sure you entered correct personal information")
  //     alert("Please make sure you entered correct personal information")
  //   }
  //   if (!formValidation){
  //     setRequestLoading(false)
  //     setRequestMessage("Please make sure you filled required fields")
  //     alert("Please make sure you filled required fields")
  //   }
  // }

        }}>Submit Request</ButtonPrimary>
</div>

      </div>

      </Box>
    </Drawer>
  )

  const renderBadges = () => {
    return <div className="flow-root">
      {
        eventIsPast ? <Badge name="Past Event"/> : <Badge name="Just Added" />
      }
    </div>
  }

  const renderActionButtons = () => {
    return (
      <div className="flow-root">
        <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
        <RWebShare
          data={{
            text: `${eventData.description}`,
            url: `https://evtgo.com/e/${id || ""}`,
            title: `${eventData.title} | Event Go`,
          }}
          onClick={() => {
          }}
        >
  <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
              />
            </svg>
            <span className="hidden sm:block ml-2.5">Share</span>
          </span>
          
        </RWebShare>
          
          <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
            <span className="hidden sm:block ml-2.5">Save</span>
          </span>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    let dateString = ""
    dateString = eventData.startDateString
    if (eventData.endDateString != null && eventData.endDateString.includes(" ")){
      const dateS = eventData.endDateString.split(" ")
      const dates = dateS[0]
      const times = dateS[1]
      if (dateString.includes(dates)){
        dateString += " - " + times
      }
    }
    if (eventData.type == 'tour'){
      dateString = "All Years Around"
    }
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          {renderBadges()}
          {renderActionButtons()}
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {eventData.title}
        </h2>

        {/* 3 */}
        <div className="">
        <span>
            <i className="las la-clock"></i>
            <span className="ml-1"> {dateString}</span>
          </span>
          <br/>
          <br/>
          <span className="">
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {eventData.address.fullAddress}</span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar 
          hasChecked 
          imgUrl={eventData.user.imageURL}
          sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {eventData.user.userName}
            </span>
          </span>
        </div>

        {/* 5 */}
        {/* <div className="w-full border-b border-neutral-100 dark:border-neutral-700" /> */}

        {/* 6 */}
        {/* <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              6 <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              6 <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              3 <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              2 <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Event Description</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {
            eventData.descriptions.en.map((itm,index) => {
              if (itm.includes('*** ')){
                const itmCount = itm.split('*** ')
                if (itmCount.length == 3){
                  //highlight word
                  return <div>
                    <span>
                  {itmCount[0]}
                  </span>
                  <span>
                  <b>{itmCount[1]}</b>
                  </span>
                  <span>
                  {itmCount[2]}
                  </span>
                  <br />
                  </div>
                }
                else{
                  //highlight line
                  return <div>
              <span>
              <b>{itm.replaceAll('*** ','')}</b>
              </span>
              <br />
              </div>
                }
              }
              else{
                return <div>
              <span>
              {itm}
              </span>
              <br />
              </div>
              }
            })
          }
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` About the property's amenities and services`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {Amenities_demos.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more 20 amenities
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Ticket Prices </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            View all available ticket prices here
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            {
              eventPrices?.map((priceItm,index) =>{
                let pricingDetails: string[] = []
          if (priceItm.priceDescriptions != null){
            pricingDetails = priceItm.priceDescriptions
          }
                return <div className={index % 2 == 0 ? "p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg" : "p-4  flex justify-between items-center space-x-4 rounded-lg"}>
                <div>
                  <span><b>{priceItm.priceDescription}</b></span><br/>
                  { pricingDetails.map((itm) => {
          if (itm.includes('*** ')){
            const itmCount = itm.split('*** ')
            if (itmCount.length == 3){
              //highlight word
              return <div>
                <span className="font-normal">
              {itmCount[0]}
              </span>
              <span className="font-normal">
              <b>{itmCount[1]}</b>
              </span>
              <span className="font-normal">
              {itmCount[2]}
              </span>
              <br />
              </div>
            }
            else{
              //highlight line
              return <div>
          <span className="font-normal">
          <b>{itm.replaceAll('*** ','')}</b>
          </span>
          <br />
          </div>
            }
          }
          else{
            return <div>
          <span className="font-normal">
          {itm}
          </span>
          <br />
          </div>
          }
          
        })}
                </div>
                <span><b>{priceItm.price == 0 ? 'FREE' : priceItm.price.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})}</b></span>
              </div>
              })
            }
          </div>
        </div>
      </div>
    );
  };

  const renderSectionAgenda = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Agenda </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            {
              eventData.agenda.map((agendaItm,index) =>{
                return <div className={index % 2 == 0 ? "p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg" : "p-4  flex justify-between items-center space-x-4 rounded-lg"}>
                <span>{'a'}</span>
                <span>{'a'}</span>
              </div>
              })
            }
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
            imgUrl={eventData.user.imageURL}
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              {eventData.user.userName}
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              {/* <StartRating /> */}
              {/* <span className="mx-2">·</span> */}
              <span> Event Partner</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          {
            eventData.user.userName
          }
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>{eventData.address.city}</span>
          </div>
          {/* <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div> */}
          {/* <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div> */}
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href={`/organizer/${eventData.orgId}`}>See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const handleImageClick = ()=>{
    router('/e/'+id+'?modal=event-collections')
  }

  const renderSectionDate = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Date</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {eventData.startDateString}
        </span>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Event Start: {eventData.startDateString}
        </span>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Event End: {eventData.endDateString}
        </span>
      </div>
    )
  }

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Agenda (23 agendas)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        {/* <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div> */}

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {eventData.address.fullAddress}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&q=${eventData.address.fullAddress.replace(' ','+')}`}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know before you buy tickets</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h3 className="text-lg font-semibold">Cancellation policy</h3>
          <div className="mt-3 text-red-500 dark:text-red-400 font-bold">Final Sale, No Cancellation</div>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {eventData.refundPolicy}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        <div>
          {eventData.terms.texts.length > 0 && <h3 className="text-lg font-semibold">Terms and Conditions</h3>}
          {
            eventData.terms.texts.map((text) => {
              return <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              {text}
            </span>
            })
          }
        </div>
      
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-1xl font-semibold">
            {'Ticket Prices'}
          </span>
          {eventData.viewCount > 0 && <SaleOffBadge desc={`Promotion ${eventData.viewCount | 0}% OFF`}/>} 
        </div>
        {/* SUM */}
        <div className="flex flex-col space-y-4">
        <span className="text-2xl font-semibold">
            {eventPriceRange}
          </span>
          {/* {
            eventPrices?.map((ticketItm) => {
              return <div style={{marginTop: 15}}>
                 <div className="flex justify-between font-semibold">
            <span>{ticketItm.priceDescription}</span>
            <span className="ml-3">{ticketItm.price.toLocaleString("en-US",{style: "currency", currency: ticketItm.priceCurrency})}</span>
          </div>
              </div>
            })
          } */}
          
        </div>
        {
          userCoupon.coupon != "" &&
          <div className="flex flex-col space-y-4 text-color-red">
        <div>
          <div className="flex justify-between font-semibold">
            <span>Coupon Applied</span>
            <span>{userCoupon.coupon}</span>
          </div>
        </div>
        </div>
        }
        

        {/* SUBMIT */}
        
        <ButtonPrimary disabled={eventData.shareURL == 'VIEWONLY' || eventIsPast || eventData.status != 'live'} onClick={()=>{
            getTicketAction()
        }}>{eventData.options.request ? "Request To Join" : "Get Tickets"}</ButtonPrimary>
        
      </div>
    );
  };

  const renderingMobileFooterLayout = (eventName: string, eventPrice: string, viewId: string, eventId:string) => {
    return (
      <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
        <div className="container flex items-center justify-between">
          <div className="">
            <span className="block text-xl font-semibold">
              {/* <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                from
              </span>
              {` `} */}
              {eventPriceRange}
            </span>
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              {eventName}
            </span>
            
          </div>
          <div className="ml-2">
          <ButtonPrimary
                sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                disabled={eventData.shareURL == 'VIEWONLY' || eventIsPast || eventData.status != 'live'}
                onClick={()=>{
                  getTicketAction()
                }}
              >
                {eventData.options.request ? "Request To Join" : "Get Tickets"}
              </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  }

  function LoadingHeaderView(){
    return (
      <Card sx={{ width: '100%', marginTop: '15px', marginBottom: '15px', height: '90vh'}}>
        <CardHeader
          avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
          title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }}/>}
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
        <Skeleton sx={{ height: '50vh' }} animation="wave" variant="rectangular" />
        <CardContent>
          <React.Fragment>
              <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={10} width="80%" />
            </React.Fragment>
        </CardContent>
      </Card>
    )
  }

  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <Helmet>
        <title>{eventData.title} | Event Go</title>
        <meta property="og:title" content={`${eventData.title} | Event Go`} />
        <meta property="og:description" content={`${eventData.description.substring(0,120)} | Event Go`} />
        <meta property="og:url" content={"https://evtgo.com/e/" + eventData.id}/>
        <meta property="og:site_name" content={`${eventData.title} | Event Go`} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={eventData.photoURL} />
      </Helmet>
      
      {eventLoading ? <LoadingHeaderView/> : <>
      
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-4 gap-1 sm:gap-2 aspect-[2/1] w-full">
          <div
            className="col-span-4 rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={handleImageClick}
          >
            {eventData.photoURLs.length > 2
            ?<MutipleImageGallery 
              mainImage={eventData.photoURLs[0]} 
              topImage={eventData.photoURLs[1]} 
              bottomImage={eventData.photoURLs[2]}
              showMoreIcon={eventData.photoURLs.length > 3}></MutipleImageGallery>
            :<img
              src={eventData.photoURL}
              alt={eventData.title}
              style={{objectPosition: 'top', objectFit: 'cover'}}
              className="absolute inset-0 object-fill rounded-md sm:rounded-xl w-full h-full"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />}            
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {RequestToJoinDrawer}
          {renderSection1()}
          {renderSection2()}
          {/* {eventData.agenda.length > 0 && renderSectionAgenda()} */}
          {renderSection4()}
          {/* {renderSection3()}
          {renderSection4()} */}
          {/* <SectionDateRange /> */}
          {renderSection5()}
          {/* {renderSection6()} */}
          {renderSection8()}
        </div>
        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
      <footer>
        {renderingMobileFooterLayout(eventData.title,eventData.price.toLocaleString('en-US',{style: 'currency', currency: 'CAD'}), eventViewId, id || "")}
      </footer>
      
      </>}
    </div>
  );
};


export default function EventDetailPage() {
  return (
    <EventDetailPagetLayout>
      <EventDetailPageContainer />
    </EventDetailPagetLayout>
  );
}
